import React, { useState } from "react";
import axios from "axios";
import { Oval } from "react-loader-spinner";
import { ReactComponent as Logo } from "../../assets/svg/logo.svg";
import { ReactComponent as LogoLight } from "../../assets/svg/logo-light.svg";
import BannerImg from "../../assets/images/banner-img.png";
import SectionTwoImage from "../../assets/images/img-tzo.png";
import Keys from "../../assets/images/keys.png";
import MailBox from "../../assets/images/mail-box.png";
import { ReactComponent as Bank } from "../../assets/svg/bank.svg";
import { ReactComponent as Builder } from "../../assets/svg/builder.svg";
import { ReactComponent as Rental } from "../../assets/svg/rental.svg";
import { ReactComponent as VOne } from "../../assets/svg/vector-one.svg";
import { ReactComponent as VTwo } from "../../assets/svg/vector-two.svg";
import { ReactComponent as VThree } from "../../assets/svg/vector-three.svg";
import Texture from "../../assets/images/texture.png";
import Family from "../../assets/images/family.png";

import { ReactComponent as Twitter } from "../../assets/svg/socials/twitter.svg";
import { ReactComponent as Facebook } from "../../assets/svg/socials/facebook.svg";
import { ReactComponent as Youtube } from "../../assets/svg/socials/youtube.svg";
import { ReactComponent as Telegram } from "../../assets/svg/socials/telegram.svg";
import { ReactComponent as Instagram } from "../../assets/svg/socials/instagram.svg";

import "./landing.scss";

const Landing = () => {
  return (
    <div className="page-container">
      <section className="nav max-width-larger">
        <div className="nav-inner">
          <div className="logo">
            <Logo />
          </div>
        </div>
      </section>

      <section className="banner">
        <div className="banner-inner max-width">
          <div className="b-left">
            <div className="b-row-one">
              Build credit score with <span>your rent.</span>
            </div>

            <div className="b-row-two">
              Whether starting from scratch or trying to improve your credit
              score, Homable helps you build your credit score with your on-time
              rental payment.
            </div>

            <div className="b-row-three">
              <GetEarlyAccess />
            </div>
          </div>

          <div className="b-right">
            <img src={BannerImg} alt="mock-up" />
          </div>
        </div>
      </section>

      <section className="section-two max-width-larger">
        <img src={Texture} className="text-vect" alt="texture" />

        <div className="s-two-wrapper max-width">
          <div className="s-t-left">
            <img src={SectionTwoImage} alt="mock-up" />
          </div>

          <div className="s-t-right">
            <div className="r-one">
              One app to make your rent payment work for you
            </div>

            <div className="r-two">
              Homable helps you improve your credit scores with automated
              on-time rental payments, rental savings, and more.
            </div>

            <div className="r-features">
              <div className="f-item">
                <Bank />

                <div className="f-i-title">Digital Bank</div>

                <div className="f-i-desc">
                  Auto save towards rent, co-save with spouses. Get cards &
                  rewards. Make ACH payments. Get cash advance for rent
                </div>
              </div>

              <div className="f-item">
                <Builder />

                <div className="f-i-title">Credit Score Builder</div>

                <div className="f-i-desc">
                  Track your credit score and automatically report on-time
                  rental payment to the credit bureaus. No excessive paperworks
                  needed.
                </div>
              </div>

              <div className="f-item">
                <Rental />

                <div className="f-i-title">Rental Payment</div>

                <div className="f-i-desc">
                  Schedule rent to your landlord directly from Homable app or
                  pay with your homable bank account/card.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-three max-width-larger">
        <div className="s-th-left">
          <img src={Family} alt="family" />
        </div>

        <div className="s-th-right">
          <VOne className="v-one" />
          <div className="s-t-r-inner">
            <div className="t-r-title">There's more to your rent</div>

            <div className="r-stats">
              <div className="s-item">
                <div className="s-number">$1,885</div>

                <div className="s-detail">
                  That’s what an average American spend on housing per month
                </div>
              </div>

              <div className="s-item">
                <div className="s-number">44.2 million</div>

                <div className="s-detail">
                  American household live in rented properties and pay rent
                  monthly.
                </div>
              </div>

              <div className="s-item">
                <div className="s-number">35%</div>

                <div className="s-detail">
                  An average American spends 35% of their income on housing
                </div>
              </div>

              <div className="s-item">
                <div className="s-number">Over 90%</div>

                <div className="s-detail">
                  of Monthly rental payments made by Americans are not reported
                  to the credit bureaus.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-four max-width-larger">
        <div className="s-f-left">
          <VTwo className="vect-sqw dsk32" />
          <VThree className="vect-sqw x2d" />

          <div className="f-l-quote">
            <span>“</span>At over 30%, housing is the highest cost of an average
            most American households<span>”</span>
          </div>
        </div>

        <div className="s-f-right">
          <img src={Keys} alt="keys" />
        </div>
      </section>

      <section className="section-five max-width-larger">
        <img src={Texture} className="text-vect" alt="texture" />

        <div className="s-fv-wrapper max-width">
          <div className="w-left">
            <img src={MailBox} alt="mail box" />
          </div>

          <div className="w-right">
            <div className="r-title">Join the waitlist</div>

            <div className="r-desc">
              Our mission is to give your rental payments the power and benefits
              that only come with mortgage payments. Be the first to know when
              we launch.
            </div>

            <GetEarlyAccess />
          </div>
        </div>
      </section>

      <section className="footer max-width-larger">
        <div className="footer-wrapper max-width">
          <div className="f-left">
            <LogoLight />

            <div className="l-desc">
              We're changing how immigrants, students, and single families pay
              rent and build their credit scores. A bank account that makes your
              rent work for you.
            </div>
          </div>

          <div className="f-right">
            <div className="r-title">Contact us</div>

            <a className="r-mail click-item" href="mailto:hello@homable.app">
              hello@homable.app
            </a>

            <div className="socials">
              <div>
                <Twitter />
              </div>
              <div>
                <Facebook />
              </div>
              <div>
                <Instagram />
              </div>
              <div>
                <Youtube />
              </div>
              <div>
                <Telegram />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const GetEarlyAccess = () => {
  const [email, setEmail] = useState("");
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return alert("Please enter a valid email!");
    }

    setLoading(true);

    axios
      .put(
        "https://api.sendgrid.com/v3/marketing/contacts",
        {
          list_ids: ["4380680d-ae92-47c4-834d-8b10b3719121"],
          contacts: [{ email }],
        },
        {
          headers: {
            accept: "*/*",
            Authorization:
              "Bearer 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",
            "Content-Type": "application/json",
          },
        }
      )
      .then(() => {
        setEmailSubmitted(true);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <div>
      {!emailSubmitted ? (
        <div className="get-access">
          <input
            type="text"
            placeholder="Enter your email address"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            disabled={loading}
          />

          <button
            className={`btn pry ${loading ? "loading" : "click-item"}`}
            onClick={handleSubmit}
            disabled={loading}
          >
            Join the waitlist{" "}
            {loading && (
              <span>
                <Oval
                  height="16"
                  width="16"
                  radius="40"
                  color="white"
                  ariaLabel="loading"
                />
              </span>
            )}
          </button>
        </div>
      ) : (
        <div className="thank-you">
          Thank you for submitting your email! ✍️{" "}
        </div>
      )}
    </div>
  );
};

export default Landing;
